/* BUTTONS*/
.quick-links .dropdown-item{
  line-height: 0px;
  padding: 10px 8px;
}
.quick-links .dropdown-item:hover{
  background-color: #cccccc;
  box-shadow: none;
}

.quick-links .dropdown-item:hover a{
  color: #333333;
}
.quick-links .dropdown-header{
  font-size: 1.25em;
}
.btn.save-button {
  border-radius: 5px;
  padding: 10px 20px;
}

.btn.save-button.btn-sm {
  padding: 5px 10px;
}

.btn.btn-icon.btn-sm {
  width: 35px;
  height: 35px;
}
.shipment-detail-sidebar .btn{
  font-size: 0.875rem;
}
.shipment-detail-sidebar .btn.btn-icon.btn-sm{
  height: 28px;
  width: 28px;
  margin-top: 3px;
}

.shipment-detail-sidebar .dropdown-toggle,
.shipment-detail-sidebar .shipment-sidebar-dropdown .dropdown{
  width: 100%;
}

.btn-rounded-border, .btn-rounded-border .dropdown-toggle {
  border-radius: 4px;
}

.user-card.tenant-logo-upload .cover-img-block .change-cover {
  right: 30px;
  left: auto;
}

.btn.btn-add-new {
  border: 1px solid #0A6CBA;
  color: #0A6CBA;
  border-radius: 17px;
  min-width: 100px;
  padding: 5px 25px;
  font-size: 1.125rem;
  line-height: 1.1;
}

.btn.btn-add-new:hover {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  color: #FFF;
  background-color: #0A6CBA;
}

.btn.btn-add-new:hover svg path {
  fill: #FFFFFF;
}


// FA buttons
.fa-button {
  font-size: 13px;
  padding: 0px 15px 0px 0px;
}

.fa-button-logo {
  font-size: 15px;
}

.fa-button-toggle-on {
  color: #0A6CBA;
}

.fa-button-toggle-off {
  color: #333;
}

.fa-button-star-on {
  color: #ffba57;
}

.fa-button-star-off {
  color: #333;
}

// --