.shipment-block-header:before {
  content: '';
  display: flex;
  background: #fff;
  width: 35px;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 35px;
  margin-left: -20px;
}

.shipment-block-header {
  height: 35px;
  width: 100%;
  color: #FFFFFF;
  background-color: #0A6CBA;
  border-radius: 0px 35px 35px 0px;
  margin-left: -16px;
}

.shipment-block-header span {
  position: absolute;
  top: 5px;
  left: 50px;
  font-size: 1rem;
  font-weight: bold;
}

.task-card.shipment-form .task-list::before {
  display: none;
}

.task-card.shipment-form .task-list .bg-c-blue {
  background-color: #0A6CBA;
}

.task-card.shipment-form .task-list::after {
  left: 18px;
  top: 25px;
  background: #fff;
}

.task-card.shipment-form .task-list li {
  padding-left: 44px;
  margin-bottom: 0;
}

.task-card.shipment-form .task-list li .task-icon {
  height: 32px;
  width: 32px;
  font-size: 1.25rem;
  text-align: center;
  padding: 0;
}

.shipment-times-block .form-group {
  display: inline-block;
  margin-top: 24px;
  margin-left: 5px;
}

.timepicker-input.display-inline {
  display: inline-block;
}

.shipment-times-block .time-icon {
  position: absolute;
  top: 2px;
  margin-left: 5px;
}

.shipment-form .form-group {
  margin-bottom: 4px;
}

.shipment-form .address-block {
  border: 1px solid #cccccc;
  padding: 10px 0;
  border-radius: 5px;
  margin-left: 0;
}

.shipment-form .shipment-edit-form .address-block {
  min-height: 450px;
}


.shipment-form .shipment-edit-form .address-block {
  min-height: 450px;
}

.shipment-form .shipment-edit-form .address-block.price-request {
  min-height: 200px;
}

.shipment-form .shipment-edit-form .address-block.price-request .left-side {
  padding-top: 59px;
}

.shipment-form .btn-type-select {
  display: inline-block;
  width: 97%;
  border: 1px solid #0A6CBA;
  color: #0A6CBA;
  font-size: 1.15rem;
  padding: 2px;
  font-weight: bold;
}

.shipment-form .btn-type-select.right-button {
  margin-left: 65px;
}

.shipment-form .btn-type-select.active {
  background-color: #0A6CBA;
  color: #FFFFFF;
}

.shipment-form .swap-button {
  position: absolute;
  right: 49.2%;
  background-color: #fff;
  border-radius: 50px;
  top: -3px;
}

.client-portal-wrapper .shipment-form .swap-button {
  right: 49.5%;
  top: -2px;
}

.shipment-form .card-body {
  padding: 10px;
}

.google-search-input {
  position: relative;
}

.shipment-form .address-block .fa-search, .google-search-input .fa-search {
  position: absolute;
  right: 7px;
  bottom: 8px;
  color: #0A6CBA;
}

.google-search-input .spinner-grow {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 7px;
  bottom: 8px;
  color: #0A6CBA;
}

.shipment-form .sidebar {
  min-height: 600px;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.shipment-form .sidebar-sticky {
  margin-top: 50px;
}

.shipment-form .sidebar .form-title {
  padding-left: 15px;
}

.shipment-form .sidebar .task-list {
  padding: 0px 0px 0px 12px;
}

.shipment-form .sidebar-title {
  color: #0A6CBA;
  font-weight: bolder;
  padding-top: 5px;
}

.shipment-form .sidebar .task-list:after{
  left: 30px;
  top: 5px;
  background-color: #0A6CBA;
  height: 92%;
}

.shipment-form .sidebar .task-list .task-icon {
  background-color: #fff;
}

.shipment-form .sidebar .task-list li {
  min-height: 60px;
}

.shipment-form .sidebar .task-list li p {
  margin-bottom: 0px;
  color: #686C71;
}

.shipment-form .sidebar .task-list li p label {
  min-width: 36px;
  text-align: right;
  margin-bottom: 0;
}

.shipment-form .shipment.sidebar .flag-component {
  width: 27px;
  height: 26px;
  margin-right: 0 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.package-row {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid #cccccc;
}

.package-closed {
}

.package-opened {

}

.package-row .col-md-1 {
  padding-right: 1px;
  padding-left: 10px;
}

.package-actions {
  position: absolute;
  top: 30px;
  right: 20px;
}

.package-row .col-md-1 {
  flex: 0 0 9.33333%;
  max-width: 9.33333%;
}

.package-row .input-group-text {
  padding: 0 5px;
}

.package-row .w-70 {
  max-width: 70px;
}

.package-row .w-160 {
  min-width: 160px;
}

.package-row .m-t-8 {
  margin-top: 8px;
}

.package-row .m-t-16 {
  margin-top: 16px;
}

.package-row .toggle .form-label {
  margin-top: 5px;
}

.package-row input[type=text]:read-only {
  background-color: #fff;
  cursor: not-allowed;
}

.sidebar-totals {
  padding: 0;
}

.sidebar-goods {
  padding: 0 16px;
}

.sidebar-totals li {
  display: block;
  margin-left: 0px;
}

.task-card.shipment-form .sidebar-goods li,
.task-card.shipment-form .sidebar-totals li {
  padding-left: 0px;
  min-height: 24px;
}

.badge.shipment-status {
  font-size: 0.785rem;
  position: relative;
  display: inline-block;
  min-width: 120px;
  color: #000000;
  font-weight: normal;
  padding: 6px 3px 5px 10px;
  text-align: left;
  border-radius: 12px;
}

.badge.shipment-status svg {
  position: absolute;
  right: 7px;
  top: 4px;
}

.shipment-status.badge.badge-secondary {
  background-color: #C4C4C4;
}

.shipment-status.badge.badge-success {
  background-color: rgba(0, 222, 0, 0.5);
}

.shipment-status.badge.badge-info {
  background-color: rgba(10, 108, 186, 0.5);
}

.shipment-form .address-block.display-view {
  width: 90%;
}

.shipment-form .address-block.display-view p {
  margin-bottom: 0.145rem;
}

.shipment-form .package-info {
  flex: 0 0 97%;
}

.shipment-detail-sidebar .btn {
  font-size: 1rem;
}

table.shipment-prices .form-group {
  margin: 0;
  padding: 4px 0;
}

.shipment-address {
  background-color: #ecf0f5;
  border-radius: 10px;
}

.shipment-row-totals .form-inline .form-label {
  margin-right: 10px;
  padding-top: 5px;
}

.shipment-row-totals .switch input[type="checkbox"] + .cr {
  top: 7px !important;
}

.shipment-row-totals .switch input[type="checkbox"] + .cr::before {
  background-color: #ffffff;
}

.refresh-contacts-btn {
  position: absolute;
  right: 20px;
  top: 0px;
  z-index: 3;
}

.toggle-group {
  padding-top: 8px;
}

.label-print-list .list-item:hover {
  font-weight: bold;
}
.form-group.shipment-price-input input{
  min-width: 80px;
}
.form-group.shipment-price-description-input input{
  min-width: 200px;
}