.client-portal-wrapper .pcoded-main-container {
  margin-top: 0px !important;
}

.client-portal-wrapper .pcoded-content {
  margin-top: 85px;
}

.m-header.client-portal-header {
  width: 0;
}

.client-portal-header.pcoded-header .navbar-nav > li:first-child {
  padding-left: 0;
}

.sidenav-horizontal-wrapper .nav.pcoded-inner-navbar {
  margin-left: 30px;
}

.client-portal-header .btn {
  padding: 5px 10px;
}

.client-portal-price-request-prices.table tbody td {
  padding: 10px;
  font-size: 16px;
}