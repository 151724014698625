/*Loading*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding-top: 40px;
}

.overlay .overlay-centered {
  position: absolute;
  top: 50%;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.7);
}

.white-overlay-opaque {
  background-color: rgb(255, 255, 255);
}

.form-heading-component{
  height: 50px;
  width: 100%;
  background-color: #0A6CBA;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2px 20px;
  font-size: 1rem;
}
.content-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
  z-index: 999;
}

.form-filled-input {
  .form-label {
    color: #686C71;
  }

  .form-control {
    border: 1px solid #CED4DA;
    border-radius: 5px;
    background-color: #EFF3F6;
    padding: 0px 10px;
  }

  .select__control, .ql-editor {
    background-color: #EFF3F6;
  }

  .select__control .select__indicator svg {
    width: 14px;
    height: 12px;
    cursor: pointer;
  }

  .select__control .select__indicator.select__clear-indicator svg {
    fill: #ff5252;
  }

  .form-control:focus {
    background-image: none;
  }

  .form-group.error .form-control {
    border-color: #ff5252;
  }

  .form-group.error .quill {
    border: 1px solid #ff5252;
  }
}

.form-filled-input.form-sm {
  input.form-control, select.form-control, .input-group-append {
    height: 30px;
  }
}

.custom-control-input {
  z-index: 6;
}

.input-group-text {
  border-radius: 5px;
  padding: 0.625rem 0.9875rem;
}

.input-group-append-on-top {
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 1;
}

.form-group input:disabled {
  background-color: #beceda;
}

.form-label .asterisk {
  font-size: 0.875rem;
  color: #0A6CBA;
  margin-left: 3px;
}

.input-group svg {
  margin-top: 2px;
  margin-left: 5px;
}

.input-group.error {
  border-bottom: 1px solid #ff5252;
}

.datepicker-input {
  width: 130px;
}

.timepicker-input {
  width: 94px;
}

.form-sm .select__value-container {
  padding: 0px 8px;
}

.form-sm .css-b8ldur-Input {
  padding-top: 0px;
  padding-bottom: 0px;
}

.form-sm .select__control {
  min-height: 30px;
}

.form-group.error .select__control {
  border-color: #ff5252;
}

.form-sm .select__dropdown-indicator {
  padding: 4px 5px;
}

.form-sm .form-group label {
  margin-bottom: 0.25rem;
}

.form-group .select-menu {
  z-index: 999 !important;
}

.react-datepicker__header {
  background-color: #FFFFFF;
}

.react-datepicker__day--weekend:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month) {
  color: #ff3333 !important;
  border-radius: 5px;
}

.react-datepicker__day--outside-month {
  color: #a6a6a6;
}

.form-filled-input .select__control.select__control--is-disabled {
  cursor: not-allowed;
  background-color: #ffffff;
}

.form-group textarea.form-control {
  min-height: 100px;
}

.email-form .form-group textarea.form-control {
  min-height: 200px;
}

.message-template-form .form-group textarea.form-control {
  min-height: 250px;
}

.form-group.textarea-editor-md .ql-editor {
  min-height: 150px;
}

.form-group.textarea-editor-lg .ql-editor {
  min-height: 300px;
}

.address-autocomplete {
  z-index: 1;
}

.address-autocomplete .dropdown-menu {
  max-width: 450px;
  overflow: hidden;
}

.address-autocomplete .dropdown-menu > .dropdown-item {
  min-width: 450px;
  max-width: 450px;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 0.75rem;
}

.address-autocomplete .dropdown-header {
  padding: 0.5rem 1rem;
  color: #333333;
  font-size: 0.875rem;
  font-weight: bold;
}

.address-autocomplete .dropdown-menu > .dropdown-item:hover {
  cursor: pointer;
  background-color: #6c757d;
  color: #fff;
}

.dropdown-menu-saved-filters > .dropdown-item {
  padding: 0.25rem 0.5rem;
}

.dropdown-menu-saved-filters > .dropdown-item:hover .btn {
  cursor: pointer;
}

.dropdown-menu-saved-filters > .dropdown-item:active {
  background-color: transparent;
}
.h-px-90 .form-control{
  height: 90px;
}

/*FILTER*/

.filter-bar .dropdown .dropdown-menu{
  width: 330px;
  padding: 15px;
}
.filter-bar .dropdown-toggle::after {
  display: none;
}
.field-has-value {
  position: absolute;
  top: 5px;
  right: 0;
  background: #4d4d4d;
  border-radius: 50px;
  font-size: 7px;
  color: #fff;
  padding: 2px 4px;
}

.filter-bar .inline-switch .switch-group {
  margin-bottom: 0;
  padding-top: 6px;
}
/*ERRORS*/
.invalid-feedback {
  display: block;
}

.form-group.switch-group {
  margin-top: 1px;
}

.switch.switch-primary {
  margin-top: -12px;
}

.PhoneInput {
  position: relative;
}

.PhoneInput .PhoneInputCountry {
  position: absolute;
  top: 8px;
  left: 8px;
}

.input-lg .PhoneInput .PhoneInputCountry {
  top: 14px;
}

.PhoneInputInput.form-control {
  padding-left: 45px;
}