.auth-wrapper .auth-content.spotRequest:not(.container) {
  max-width: 1280px;
  width: 1280px;
}

.auth-wrapper .auth-content.spotRequest:not(.container) .card-body {
  padding: 10px 25px;
}

.spotRequest .list-group-item {
  background-color: transparent;
  padding: 4px 0px;
  border: none;
}

.spotRequest .dataTable-wrapper-regular thead th {
  padding: 5px 10px;
}

.spotRequest .spotRequest-prices .form-group {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .auth-wrapper .auth-content.spotRequest:not(.container) {
    max-width: 991px;
    width: 990px;
  }
  .spotRequest .input-group .pointer {
    display: none;
  }
  .spotRequest .shipment-address {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .auth-wrapper .auth-content.spotRequest:not(.container) {
    max-width: 768px;
    width: 750px;
  }
}

@media only screen and (max-width: 500px) {
  .auth-wrapper .auth-content.spotRequest:not(.container) {
    max-width: 450px;
    width: 450px;
  }
  .auth-content.spotRequest .buttons {
    margin-top: 15px;
  }
}