.pcoded-navbar.mob-open ~ .pcoded-header::before, .pcoded-navbar.mob-open ~ .pcoded-main-container::before, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header::before, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container::before {
  z-index: 0;
}

@media (max-width: 1835px) {
  .nav-tabs.nav-tabs-buttons .nav-link {
    max-width: 150px;
    min-width: 150px;
  }
}

@media (max-width: 1485px) {
  .nav-tabs.nav-tabs-buttons .nav-link {
    max-width: 125px;
    min-width: 125px;
  }
}

@media (max-width: 1310px) {
  .nav-tabs.nav-tabs-buttons .nav-link {
    max-width: 105px;
    min-width: 105px;
  }
}
@media (max-width: 991.98px) {
  .nav-tabs.nav-tabs-buttons .nav-link {
    max-width: 105px;
    min-width: 105px;
  }
  .shipment-times-block .form-group {
    margin-top: 5px;
    margin-left: 15px;
  }
  .package-row .w-70 {
    max-width: 95px;
  }
  .dataTable td {
    height: auto;
  }
}

.tablet-add-shipment.btn {
  left: 70px;
  font-size: 14px;
}