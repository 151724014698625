.shipping-order-shipment{
  border: 1px solid #CED4DA;
  border-radius: 15px;
  margin-bottom: 5px;
}

.shipping-order-shipment.selected {
  border: 1px solid #0a6cba;
}

.shipping-order-shipment header {
  cursor: pointer;
  padding: 5px 0;
}

.shipping-order-shipment .header-opened {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 5px;
  background-color: #eff3f6;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.shipping-order-shipment .header-closed:hover {
  background-color: #eff3f6;
  border-radius: 15px;
}

.shipping-order-shipment .custom-checkbox {
  position: absolute;
  top: 30%;
}

.shipping-order-actions .col-md-1 {
  max-width: 50px;
}

.shipping-order-actions .btn.btn-icon {
  width: 26px;
  height: 26px;
}


.shipping-order-map-wrapper {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  color: #686C71;
}

.route-address {
  color: #686C71;
  margin-bottom: 5px;
}

.route-address .fa {
  color: rgba(10, 108, 186, 0.5);
}

.route-dnd {
  border-radius: 4px;
  border: 1px solid #CED4DA;
  padding: 5px 10px;
  cursor: move;
  width: 100%;
}

.shipping-order-form .form-group.textarea-editor-md .ql-editor {
  min-height: 202px;
}