.control-tower-dataTable thead tr th {
  background-color: #eaeaea !important;
  font-weight: bold;
  text-transform: none;
}

.control-tower-dataTable tbody td {
  vertical-align: inherit !important;
}




.control-tower-tab {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
}

.control-tower-tab-separator {
  display: inline-block;
  border-right: 1px solid #868e96;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 16px;
}



.control-tower-resourceCalendar {
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  text-align: center;
  width: 100%;
  min-width: 800px;
}

.control-tower-resourceCalendar thead {
  background: #005792;
  color: white;
}

.control-tower-resourceCalendar .today {
  border-left: 1px solid white;
  border-right: 1px solid white;
  font-weight: 800;
}

.control-tower-resourceCalendar col:nth-child(odd) {
  background:white;
}

.control-tower-resourceCalendar col:nth-child(even) {
  background:#EEE;
}

.control-tower-resourceCalendar th {
  width: 12.5%;
  height: 50px;
  vertical-align: middle;
}

.control-tower-resourceCalendar td {
  padding: 0px;
  width: 12.5%;
  height: 50px;
  border-top: 1px solid black;
  border-bottom: 1px solid darkgray;
  vertical-align: middle;
}

.shipmentBarContainer {
  position: absolute; 
  height: 39px;
  margin-top: -20px; 
  border-collapse: collapse; 
}

.shipmentBar {
  padding-left: 3px;
  height: 39px;
  border-collapse: collapse; 
  border: 1px solid black;
  border-radius: 5px;
  font-weight: 800;
  font-size: smaller;
  display: flex;
  align-items: center;
}

.shipmentBar.underflow {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
}

.shipmentBar.overflow {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}

.shipmentBar.delivered {
  background-color: lightgreen;
}

.shipmentBar.inProgress {
  background-color: white;
}

.shipmentBar.delayed {
  background-color: #ffaaaa;
}

.shipmentBar a {
  color: black;
}

.shipmentBarDetails {
  margin-left: 3px; 
  height: 38px;
  border-collapse: collapse; 
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: left; 
}

.shipmentBarDetails.directionRect {
  margin-left: 8px;
  margin-right: 8px;
}

.menu-icon-gray {
  color: #868e96 !important;
}

.menu-icon-red {
  color: #ff7070 !important;
}
