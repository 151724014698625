/* DATATABLES*/
.dataTable-wrapper {
  min-height: 600px;
}

.dataTable thead {
  position: relative;
  z-index: 0;
  opacity: 0.99;
}

.no-background {
  background-color: transparent;
}

.dataTable-wrapper.dataTable-wrapper-no-shadow,
.dataTable-wrapper-no-shadow.dataTable-wrapper-regular, .no-shadow, .no-shadow:hover {
  box-shadow: none;
}

.user-profile-list table tr th:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.user-profile-list table tr th:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.user-profile-list .dataTable-wrapper thead th {
  background: #005792 !important;
  padding: 0.75rem;
  text-transform: none;
  font-weight: bold;
  color: #FFF;
}

.dataTable-wrapper-regular thead th {
  background: #ecf0f5 !important;
  text-transform: none;
  font-weight: bold;
  padding: 15px 10px;
}

.dataTable td {
  height: 58px;
}

.dataTable-wrapper-regular tbody td {
  vertical-align: middle;
  padding: 3px 10px;

}

.dataTable.border-collapse {
  border-collapse: separate;
}

.dataTable-wrapper-regular {
  table {
    tbody {
      tr {
        .overlay-edit {
          .btn {
            border-radius: 50%;
            margin: 0 3px;
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:hover {
          .overlay-edit {
            opacity: 1;
          }
        }
      }
    }
  }
}

.notifications-dataTable {
  max-height: 400px;
  overflow: auto;
}

.dataTable {
  padding: 0 5px;
}

.control-tower-dataTable.dataTable, .control-tower-dataTable .dataTable {
  padding: 0;
}

.dataTable td p {
  margin-bottom: 0px;
}

.user-profile-list table {
  border-spacing: 0 10px;
}

.user-profile-list table tr.bg-row-muted td {
  background: rgba(215, 218, 218, 0.8);
}

.user-profile-list h1 {
  font-size: 1.75rem;
}

.dataTable .MuiIconButton-colorPrimary {
  padding: 1px;
}

.dataTable th .MuiIconButton-label svg {
  fill: #fff;
}

.user-profile-list table tbody tr:hover td {
  cursor: default;
}

.dataTable .company-name {
  margin-left: 0px;
}

.user-profile-list table tbody tr {
  box-shadow: 0 2px 6px -1px rgba(69, 90, 100, .3);
}

.dataTable td {
  border-top: 1px solid #e3eaef !important;
  font-size: 0.875rem;
}

.dataTable .td-vertical-fill {
  position: relative;
  height: 100%;
}

.dataTable .td-vertical-bottom {
  bottom: 0;
  position: absolute;
}


/* PROJECT LIST*/
.project-list .progress {
  float: left;
  width: 85%;
}

.project-list .progress-numbers {
  margin-left: 10px;
  margin-top: -6px;
}

.table-responsive.public-spot-request {
  display: inline-table;
}

/*table forms*/
.dataTable-wrapper-regular .form-group .select__control{
  min-width: 200px;
}

.dataTable-wrapper-regular .override_select__control_minwidth_125 .form-group .select__control{
  min-width: 125px;
}

.dataTable-wrapper-regular .override_select__control_minwidth_75 .form-group .select__control{
  min-width: 75px;
}