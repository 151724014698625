// table overflow for DatePicker
.table-responsive.overflow-visible {
  overflow-x: visible !important;
}

// selected row first
.table-striped tbody tr.doublerow-selected:nth-of-type(2n+1) {
  border-top: 2px solid #0A6CBA;
  border-bottom: 0px;
  background-color: #e7f3fe;
}

// selected row second
.table-striped tbody tr.doublerow-selected:nth-of-type(2n+2) {
  border-top: 0px;
  border-bottom: 2px solid #0A6CBA;
  background-color: #e7f3fe;
}

// regular row background "even"
.table-striped tbody tr.doublerow:nth-of-type(4n+1), tr.doublerow:nth-of-type(4n+2) {    
  background-color: #fff !important;
}

// regular row background "odd"
.table-striped tbody tr.doublerow:nth-of-type(4n+3), tr.doublerow:nth-of-type(4n+4) {
  background-color: transparentize($primary-color,0.97) !important;
}

// regular row td borders first
.table-striped tbody tr td.doublerow-first { 
  border-top: 1px solid #e2e5e8;
  border-bottom: 0px !important;
}
// regular row td borders second
.table-striped tbody tr td.doublerow-second {
  border-top: 0px !important;
  border-bottom: 1px solid #e2e5e8;
}

// add button row
.table-striped tbody tr.doublerow-add {
  background-color: #fff !important;
}
