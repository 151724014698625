.hidden {
  display: none !important;
}

.clearfix {
  clear: both;
}

.display-block {
  display: inline-block;
}

.display-as-block {
  display: block;
}

.no-border {
  border: none !important;
}

.page-header-wrapper {
  background: $header-dark-background;
  margin-bottom: 10px;
}

.bg-theme-dark {
  background: #081a33;
}

.bg-theme {
  background-color: #0A6CBA;
}

.font-theme {
  color: #0A6CBA;
}

.bg-theme-light {
  background-color: #EFF3F6;
}

.bg-price-request {
  background-color: #006f94 !important;
}

.bg-invoice {
  background-color: #00946f !important;
}

.bg-shipping-order {
  background-color: #344e60 !important;
}

.form-title {
  position: relative;
  color: #0A6CBA;
}

.m-t-33 {
  margin-top: 33px;
}

.shadow-right {
  box-shadow: 5px -3px 5px -3px rgba(0, 0, 0, 0.15);
}

.flag-component {
  height: 22px;
  width: 24px;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  display: inline-block;
}

.overlay-edit {
  opacity: 1;
  text-align: right;
}

.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}

.font-bold {
  font-weight: bold;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}


/**BODY**/
body.background-dark, .background-dark .pcoded-navbar {
  background: #081A33;
}

/** HEADER **/
.language-switch .flag-component {
  border: 1px solid #081a33;
}

.language-switch .dropdown-menu.show.dropdown-menu-right {
  min-width: 145px;
  padding: 0;
}

.language-switch ul {
  list-style: none;
  padding: 0;
}

.language-switch ul li {
  margin: 15px;
}

.language-switch .noti-head {
  background-color: #101b33;
  color: #ffffff;
}

.language-switch ul li:hover {
  font-weight: bold;
}

.client-portal-wrapper .language-switch button {
  padding-top: 0px !important;
}

.pcoded-header .dropdown.drp-user .dropdown-toggle {
  width: auto;
  line-height: 0;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 7px 15px;
  background: #fff;
  color: #101B33;
}

.pcoded-header .dropdown.drp-user .btn > i {
  margin-right: 0;
}

header.navbar {
  border-bottom: 2px solid #D16A1F !important;
}

/** CARDS **/
.card.card-body-padding {
  .card-body {
    padding: 3rem !important;
  }
}

/*SIDEBAR MENU*/
.pcoded-navbar .pcoded-inner-navbar li > a {
  margin: 1px 10px;
  padding: 5px 10px;
}

.pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #0A6CBA;
}

.page-content-header {
  border-bottom: 1px solid #ecf0f5;
}

.pcoded-content-line {
  display: block;
  background: #081A33;
  height: 90px;
}

.pcoded-content-margin {
  margin-top: -80px;
}

.dataTables_length {
  display: none;
}

.sidebar-badge {
  margin-top: 7px;
  border-radius: 50px !important;
}

.badge-light-success {
  background: rgba(0, 222, 0, 0.5);
}

/* NAV tabs*/
.nav-tabs-wrapper {
  background: #0A1E39;
  color: #FFF;
  padding: 20px;
}

.theme-system-tabs {
  background: #FFF !important;
  color: #333 !important;
}

.nav.nav-tabs.nav-tabs-buttons {
  border-bottom: none;
}

.nav-tabs.nav-tabs-buttons .nav-link {
  border: 1px solid #FFF;
  border-radius: 5px;
  margin-right: 15px;
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px;
}

.nav-tabs.nav-tabs-buttons .nav-link.active,
.nav-tabs.nav-tabs-buttons .nav-link:hover,
.nav-tabs.nav-tabs-buttons .nav-item.show .nav-link {
  background-color: #0A6CBA;
  border: 1px solid #0A6CBA;
  border-radius: 5px;
}

.nav-tabs.nav-tabs-buttons .nav-link i {
  padding: 3px;
  color: #FFF;
  font-size: 20px;
}

.nav-tabs.nav-tabs-buttons .nav-link.active {
  background-size: 0;
  border: 1px solid #0A6CBA;
}

.nav-tabs.nav-tabs-buttons.nav-fill .nav-item {
  text-align: left;
  width: 10%;
}

.nav-tabs .nav-link.nav-item-disabled, .nav-tabs .nav-link.nav-item-disabled:hover{
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #999999 !important;
}

/* NAV MINITABS */

.nav-minitab {
  display: inline-block;
  padding-right: 20px;
  font-size: 16px;
}

.nav-minitab-separator {
  display: inline-block;
  border-right: 1px solid #868e96;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 16px;
}

.nav-minitab-disabled {
  color: #999999 !important;
}

.nav-minitab-current {
  font-weight: bold;
}

/* MODAL */
.modal-header .modal-title {
  color: #0A6CBA;
  font-size: 18px;
}

.modal-header .modal-title small {
  display: block;
  font-size: 14px;
  color: #686C71;
}

.modal-header button {
  outline: none;
  font-size: 1.8rem;
}

.modal-dialog.modal-md {
  max-width: 600px;
}

.confirm-modal .modal-header {
  padding: 10px 20px;
}

.confirm-modal .modal-footer {
  padding: 20px;
}

.confirm-modal.no-border .modal-header {
  border-bottom: none;
}

.confirm-modal.no-border .modal-footer {
  border-top: none;
}

.confirm-modal .modal-footer .btn {
  border-radius: 5px;
}

.confirm-modal .modal-footer .btn-secondary {
  background-color: #FFFFFF;
  color: #333333;

}

.confirm-modal .modal-footer .btn-default {
  border-color: #0A6CBA;
  background-color: #0A6CBA;
  color: #FFFFFF;
}

/* NAV SUB MENU*/
.nav-vertical-tabs .nav-item {
  background: #FFFFFF;
  margin-bottom: 7px;
  border-radius: 6px;
}

.nav-vertical-tabs .nav-item .icon {
  color: #FFFFFF;
  border-radius: 5px;
  padding: 5px 5px 8px 5px;
  text-align: center;
}

.nav-vertical-tabs .nav-item a {
  color: #686C71;
  padding: 15px 0px 15px 25px;
}

.nav-vertical-tabs .nav-item a.active,
.nav-vertical-tabs .nav-item:hover {
  background: #0A6CBA;
}

.nav-vertical-tabs .nav-item:hover a {
  color: #FFFFFF;
}

.nav-vertical-tabs .nav-item:hover .icon,
.nav-vertical-tabs .nav-item a.active .icon {
  background: #FFFFFF;
  color: #0A6CBA;
}

/* WIDTH */
.w-px-30 {
  width: 30px;
}

.w-px-50 {
  width: 50px;
}

.w-px-75 {
  width: 75px;
}

.w-px-100 {
  width: 100px;
}

.w-px-125 {
  width: 125px;
}

.w-px-150 {
  width: 150px;
}

.w-px-160 {
  width: 160px;
}

.w-px-230 {
  width: 230px;
}

.min-vw-120 {
  min-width: 120px !important;
}

.min-vh-120 {
  min-height: 120px !important;
}

.min-vh-225 {
  min-height: 225px !important;
}

.min-vh-240 {
  min-height: 240px !important;
}

.drawer-sidebar {
  z-index: 1040 !important;
}

.ladgend {
  width: 12px;
  display: inline-block;
  height: 12px;
  border-radius: 30px;
  margin-right: .625rem;
  vertical-align: middle;
}

.badge-light-primary.ladgend.darker {
  background: rgba(70, 128, 255, 0.7);
}

/* ADDRESS COMPONENT */

.address-info .company-name {
  /* margin-left: 35px;*/
}

.platform-search {
  color: #373a3c;
  line-height: 15px;
  padding-top: 10px;
  border-top: 1px solid #c3c3c3;
}

.project-widget .card {
  box-shadow: none;
}

/* COMMENT COMPONENT */
.comment-card {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 7px 15px;
  margin-bottom: 10px;
  background: #fff;
}

.comment-card .action-button-row {
  display: flex;
  flex-wrap: wrap;
}

.comment-card .action-button-row .pull-right {
  margin-left: auto;
}

.comment-card .entity-link {
  font-size: larger;
}

//*

.d-tooltip-absolute {
  position: absolute;
  top: 3px;
  margin-left: 3px;
}

.form-inline .d-tooltip-absolute {
  position: relative;
  top: 0px;
}

.MuiTooltip-popper p {
  padding: 3px 5px;
  margin: 0;
  line-height: 17px;
  font-size: 12px !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 12px !important;
  background-color: #4d4d4d;
  font-family: "Open Sans", sans-serif;
}

.tooltip-noMaxWidth {
  max-width: none !important;
}

.user-letters {
  background: #6c757d;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 700;
  width: 24px;
  height: 24px;
}

/* Document clips  */
.doc-verified {
  color: $success-color
}

.doc-unverified {
  color: $danger-color;
}
